import axios from 'axios'; //change this
import apiconfig from '../apiconfig';
import useAuth from './useAuth';

function useRefreshToken() {
    const { refreshToken, repleaceAccesToken } = useAuth();

    const refresh = async () => {
        try {
            const body = JSON.stringify({ refresh: refreshToken });
            const config = { headers: { 'Content-Type': 'application/json' } };
            const response = await axios.post(apiconfig.TOKEN_REFRESH, body, config);
            repleaceAccesToken(response?.data?.access);
            return response?.data?.access;
        }
        catch (err) {
            console.log(err);
            return null;
        }
    }
    return refresh;
}

export default useRefreshToken;
