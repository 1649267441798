// src/analytics.js
export const trackPageView = (url) => {
    if (typeof window.gtag === "function") {
      window.gtag("event", "page_view", {
        page_path: url,                  // This sets the page path
        page_location: window.location.href, // This sets the full URL, including hostname and protocol
      });
      window.gtag("event", "screen_view", {
        app_name: "CRONBE",
        page_title: url,
        'screen_name': url,
      });
    }
  };