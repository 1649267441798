import { SettingOutlined, SlackOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, Input, message, Row, Tabs, Typography } from 'antd';
import Card from 'antd/es/card/Card';
import { useState } from "react";
import { MdLock, MdOutlineWebhook, MdPower } from "react-icons/md";
import { RiDiscordLine } from "react-icons/ri";
import { Link } from 'react-router-dom';
import apiconfig from '../apiconfig';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { isIPV4Address, isPrivateIP } from '../utils/utils';
const { Title, Text } = Typography;
const { Item } = Form;

const AccountSettingsPage = () => {
    const { authTokens, userProfile, setUserData } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [messageApi, contextHolder] = message.useMessage();

    // Forms
    const [form_user_data] = Form.useForm();
    const [form_integrations] = Form.useForm();

    // Form watch
    const discordNotify = Form.useWatch('discord_notify', form_integrations);
    const slackNotify = Form.useWatch('slack_notify', form_integrations);
    const webhookNotify = Form.useWatch('webhook_notify', form_integrations);
    const [updateButtonLoading, setUpdateButtonLoading] = useState(false);

    const updateUser = async (values) => {
        setUpdateButtonLoading(true)
        const data = {
            ...userProfile
        };
        
        data.first_name = form_user_data.getFieldValue('first_name');
        data.last_name =  form_user_data.getFieldValue('last_name');

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens?.access}`
            }
        };

        try {
            const response = await axiosPrivate.patch(apiconfig.USER_DATA, data, config);
            if (response.status === 200) {
                setUpdateButtonLoading(false)
                setUserData(response.data);
            }
        } catch (err) {
            console.error(err);
            messageApi.open({
                type: 'error',
                content: 'Something went wrong!',
            });
            return
        }
        messageApi.open({
            type: 'success',
            content: 'Account data updated successfully!',
        });
        setUpdateButtonLoading(false)
    };

    const updateIntegrations = async (values) => {
        setUpdateButtonLoading(true)
        const data = {
            ...userProfile
        };

        data.user_profile = { ...form_integrations.getFieldsValue() };

        if (!form_integrations.getFieldValue('discord_notify')) {
            data.user_profile.discord_url = '';
        }

        if (!form_integrations.getFieldValue('slack_notify')) {
            data.user_profile.slack_url = '';
        }

        if (!form_integrations.getFieldValue('webhook_notify')) {
            data.user_profile.webhook_url = '';
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens?.access}`
            }
        };

        try {
            const response = await axiosPrivate.patch(apiconfig.USER_DATA, data, config);
            if (response.status === 200) {
                setUpdateButtonLoading(false)
                setUserData(response.data);
            }
        } catch (err) {
            console.error(err);
            messageApi.open({
                type: 'error',
                content: 'Something went wrong!',
            });
            return
        }
        messageApi.open({
            type: 'success',
            content: 'Account data updated successfully!',
        });
        setUpdateButtonLoading(false)
    };

    const validateDiscordURL = (rule, value) => {
        if (!value)
            return Promise.reject(new Error('URL cannot be empty'));
        try {
            const url = new URL(value);
            if (url.protocol === 'http:') {
                return Promise.reject(new Error('Use HTTPS protocol'));
            }
            if (url.hostname !== 'discord.com') {
                return Promise.reject(new Error('Hostname must be discord.com'));
            }
            return Promise.resolve();
        }
        catch (e) {
            return Promise.reject(new Error('Invalid URL'));
        }
    };

    const validateSlackURL = (rule, value) => {
        if (!value)
            return Promise.reject(new Error('URL cannot be empty'));
        try {
            const url = new URL(value);
            if (url.protocol === 'http:')
                return Promise.reject(new Error('Use HTTPS protocol'));

            if (url.hostname !== 'hooks.slack.com')
                return Promise.reject(new Error('Hostname must be hooks.slack.com'));

            return Promise.resolve();
        }
        catch (e) {
            return Promise.reject(new Error('Invalid URL'));
        }
    };

    const validateWebhookURL = (rule, value) => {
        if (!value)
            return Promise.reject(new Error('URL cannot be empty'));
        try {
            const url = new URL(value);
            if (url.hostname === 'localhost' || url.hostname === '127.0.0.1')
                return Promise.reject(new Error('URL cannot be localhost or 127.0.0.1'));

            if (url.hostname === 'cronbe.com')
                return Promise.reject(new Error('URL cannot be cronbe.com'));

            if (isIPV4Address(url.hostname)) {
                if (isPrivateIP(url.hostname)) {
                    return Promise.reject(new Error('IP address cannot be private!'));
                }
            }
            return Promise.resolve();
        }
        catch (e) {
            return Promise.reject(new Error('Invalid URL'));
        }
    };

    const onDiscordCheckboxChange = (e) => {
        if (discordNotify)
            form_integrations.resetFields(['discord_url']);
    };
    const onSlackCheckboxChange = (e) => {
        if (slackNotify)
            form_integrations.resetFields(['slack_url']);
    };
    const onWebhookCheckboxChange = (e) => {
        if (webhookNotify)
            form_integrations.resetFields(['webhook_url']);
    };

    const items = [
        {
            key: 1,
            forceRender: true,
            label: <Text strong> <UserOutlined /> USER DATA</Text>,
            children:
                <>
                    <Form
                        layout='vertical'
                        form={form_user_data}
                        requiredMark={'optional'}
                        onFinish={updateUser}
                        initialValues={{
                            email: userProfile?.email,
                            first_name: userProfile?.first_name,
                            last_name: userProfile?.last_name,
                        }}
                    >
                        <Item label='Account email' name='email' rules={[{ type: 'email', required: true }]}>
                            <Input disabled placeholder='Email'></Input>
                        </Item>
                        <Item label='First name' name='first_name' rules={[{ required: true }]}>
                            <Input placeholder='First name'></Input>
                        </Item>
                        <Item label='Last name' name='last_name' rules={[{ required: false }]}>
                            <Input placeholder='Last name'></Input>
                        </Item>
                        <Item>
                            <Button
                                icon={null}
                                size="large"
                                type="primary"
                                htmlType="submit"
                                loading={updateButtonLoading}
                            >
                                Update user data
                            </Button>
                        </Item>
                    </Form>
                </>
        },
        {
            key: 2,
            forceRender: true,
            label: <Text strong><MdPower /> INTEGRATIONS</Text>,
            children:
                <>
                    <Form
                        layout='vertical'
                        form={form_integrations}
                        requiredMark={'optional'}
                        onFinish={updateIntegrations}
                        initialValues={{
                            discord_url: userProfile?.user_profile?.discord_url,
                            slack_url: userProfile?.user_profile?.slack_url,
                            webhook_url: userProfile?.user_profile?.webhook_url,
                            discord_notify: userProfile?.user_profile?.discord_notify,
                            slack_notify: userProfile?.user_profile?.slack_notify,
                            webhook_notify: userProfile?.user_profile?.webhook_notify,
                        }}
                    >
                        <Divider><RiDiscordLine /> Discord</Divider>
                        <Item
                            wrapperCol={{ offset: 0, span: 24, }}
                            label='Discord'
                            name='discord_url'
                            rules={discordNotify ? [
                                { required: discordNotify, validator: validateDiscordURL },
                            ] : []}
                        >
                            <Input
                                placeholder='https://discord.com/api/webhooks/{webhook.id}/{webhook.token}'
                                addonBefore={'URL'}
                                disabled={!discordNotify}
                            ></Input>
                        </Item>
                        <Item
                            name="discord_notify"
                            valuePropName="checked"
                            wrapperCol={{ offset: 0, span: 16, }}
                        >
                            <Checkbox onChange={onDiscordCheckboxChange}>Discord notifications</Checkbox>
                        </Item>
                        <Divider><SlackOutlined /> Slack</Divider>
                        <Item
                            label='Slack'
                            name='slack_url'
                            rules={slackNotify ? [
                                { required: slackNotify, validator: validateSlackURL },
                            ] : []}
                        >
                            <Input
                                placeholder='https://hooks.slack.com/services/{workspace.id}/{channel.id}/{webhook.token}'
                                addonBefore={'URL'}
                                disabled={!slackNotify}
                            ></Input>
                        </Item>
                        <Item
                            name="slack_notify"
                            valuePropName="checked"
                            wrapperCol={{ offset: 0, span: 16, }}
                        >
                            <Checkbox onChange={onSlackCheckboxChange}>Slack notifications</Checkbox>
                        </Item>
                        <Divider><MdOutlineWebhook /> Webhook</Divider>
                        <Item
                            label='Webhook'
                            name='webhook_url'
                            rules={webhookNotify ? [
                                { required: webhookNotify, validator: validateWebhookURL },
                            ] : []}
                        >
                            <Input
                                placeholder='https://example.com/webhook/'
                                addonBefore={'URL'}
                                disabled={!webhookNotify}
                            ></Input>
                        </Item>
                        <Item
                            name="webhook_notify"
                            valuePropName="checked"
                            wrapperCol={{ offset: 0, span: 16, }}
                        >
                            <Checkbox onChange={onWebhookCheckboxChange}>Webhook notifications</Checkbox>
                        </Item>
                        <Item>
                            <Button
                                icon={null}
                                size="large"
                                type="primary"
                                htmlType="submit"
                                loading={updateButtonLoading}
                            >
                                Update integrations
                            </Button>
                        </Item>
                    </Form>
                </>
        },
        {
            key: 3,
            forceRender: true,
            label: <Text strong> <MdLock /> PASSWORD</Text>,
            children:
                <>
                    <Row justify='left'>
                        <Link to='/reset_password'>
                            <Button
                                size="large"
                                type="primary"
                                loading={updateButtonLoading}
                            >
                                Change password
                            </Button>
                        </Link>
                    </Row>
                </>
        },

    ]
    return (
        <>
            {contextHolder}
            <Row justify='center'>
                <Title level={2}><SettingOutlined /> Settings</Title>
            </Row>
            <Card >
                <Row justify='center'>
                    <Col span='12'>
                        <Tabs defaultActiveKey={1} items={items} />
                    </Col>
                </Row>
            </Card >
        </>
    )
}




export default AccountSettingsPage;