import { Button, Card, Form, Input, Layout, Typography, message } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import apiconfig from "../apiconfig";
import { HTTP_204_NO_CONTENT } from "../utils/http_codes";
const { Title } = Typography;
const { Item } = Form;
const RequestPassswordReset = () => {
    const [formEmail,] = useState('');
    const [resetPasswordButtonLoading, setResetPasswordButtonLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const passwordResetFailed = () => {
        messageApi.open({
            type: "error",
            content: "Can't change the password.",
        });
    };
    const passwordResetSucceeded = () => {
        messageApi.open({
            type: "success",
            content: "Passowrd change request accepted. Check your mailbox.",
        });
    };

    const emailRef = useRef();
    useEffect(() => { emailRef.current.focus(); }, []);

    const handleOnFinish = async (e) => {
        setResetPasswordButtonLoading(true)
        try {
            let response = await axios.post(apiconfig.USER_REQUEST_PASSWORD_RESET,
                {
                    "email": e.email,
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                })

            if (response.status === HTTP_204_NO_CONTENT) {
                passwordResetSucceeded()
            }
        }
        catch (err) {
            passwordResetFailed()
        }
        setResetPasswordButtonLoading(false)
    }

    return (
        <div>
            <Layout style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                {contextHolder}
                <Card>
                    <Title level={1}>Request password reset</Title>
                    <Form
                        layout="vertical"
                        onFinish={handleOnFinish}
                        onFinishFailed={null}
                        autoComplete="off"
                    >
                        <Item
                            name='email'
                            label='Email'
                            wrapperCol={{ offset: 0, span: 24, }}
                            rules={[
                                { type: 'email', message: 'The input is not a valid email!', },
                                { required: true, message: 'Please input your email!', },]}
                        >
                            <Input
                                size='large'
                                ref={emailRef}
                                value={formEmail}
                                placeholder='Your email'
                                onChange={null}
                            />
                        </Item>
                        <Item>
                            <Button
                                block
                                size='large'
                                htmlType='submit'
                                type='primary'
                                loading={resetPasswordButtonLoading}
                            >
                                Reset password
                            </Button>
                        </Item>
                    </Form>
                </Card>
            </Layout>
        </div>
    )
}
export default RequestPassswordReset;