import { Button, Card, Form, Input, Layout, Typography, message } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import apiconfig from "../apiconfig";
import { HTTP_204_NO_CONTENT } from "../utils/http_codes";
const { Title } = Typography;
const { Item } = Form;
const ResetPasswordConfirm = () => {
    const [formPassword, setFormPassword] = useState('');
    const [formRePassword, setFormRePassword] = useState('');
    const [changeButtonLoading, setChangeButtonLoading] = useState(false);
    const { uid, token } = useParams();
    const [messageApi, contextHolder] = message.useMessage();
    const passwordChangeFailed = () => {
        messageApi.open({
            type: "error",
            content: "Can't change the password.",
        });
    };
    const passwordChangeSucceeded = () => {
        messageApi.open({
            type: "success",
            content: "Passowrd changed.",
        });
    };

    const newPasswordRef = useRef();
    useEffect(() => { newPasswordRef.current.focus(); }, []);

    const handleOnFinish = async (e) => {
        setChangeButtonLoading(true)
        const config = { headers: { 'Content-Type': 'application/json' } }
        const body = JSON.stringify({
            "uid": uid,
            "token": token,
            "new_password": e.new_password,
            "re_new_password": e.re_new_password
        })

        try {
            let response = await axios.post(apiconfig.USER_RESET_PASSWORD_CONFIRM, body, config)
            if (response.status === HTTP_204_NO_CONTENT) {
                passwordChangeSucceeded()
                setFormPassword('')
                setFormRePassword('')
            }
        }
        catch (err) {
            passwordChangeFailed()
        }
        setChangeButtonLoading(false)
    }

    return (
        <div>
            <Layout style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                {contextHolder}
                <Card>
                    <Title level={1}>Password reset</Title>
                    <Form
                        layout="vertical"
                        onFinish={handleOnFinish}
                        onFinishFailed={null}
                        autoComplete="off"
                    >
                        <Item
                            name='new_password'
                            label='New password'
                            wrapperCol={{ offset: 0, span: 24, }}
                            rules={[
                                { required: true, message: 'Please input your password!', },]}
                        >
                            <Input.Password
                                size='large'
                                ref={newPasswordRef}
                                value={formPassword}
                                placeholder='New password'
                                onChange={null}
                            />
                        </Item>
                        <Item
                            name='re_new_password'
                            label='Confirm new password'
                            wrapperCol={{ offset: 0, span: 24, }}
                            rules={[
                                { required: true, message: 'Please input your password!', },]}
                        >
                            <Input.Password
                                size='large'
                                ref={null}
                                value={formRePassword}
                                placeholder='Retype new password'
                                onChange={null}
                            />
                        </Item>
                        <Item>
                            <Button
                                block
                                size='large'
                                htmlType='submit'
                                type='primary'
                                loading={changeButtonLoading}
                            >
                                Reset password
                            </Button>
                        </Item>
                    </Form>
                </Card>
            </Layout>
        </div>
    )
}
export default ResetPasswordConfirm;