import { ConfigProvider, theme } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './context/AuthContext';


// INFO
// https://ant.design/docs/spec/dark#color-palette-generator
// https://ant.design/docs/spec/colors#palette-generation-tool
// https://www.antforfigma.com/docs/colors
// https://ant.design/theme-editor

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          token:
          {
            colorPrimary: '#52c41a',
          },
          components:
          {
            Layout:
            {
              // headerBg: '#F5F5F5',// F5F5F5
              headerBg: 'rgba(255, 255, 255, 0.04)',// F5F5F5

            },
            Menu:
            {

            }
          }

          // or for combined algorithms:
          // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm, theme.defaultAlgorithm],
        }}
      >
        <App />
      </ConfigProvider>
    </AuthProvider>
  </BrowserRouter>

);