import { Button, Col, Layout, Row, Space, Typography, theme } from 'antd';
import React from 'react';
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const { Header, Content, Footer } = Layout;
const { Title, Paragraph, Text } = Typography;

function TermsOfService() {
  const { isRefreshExpired } = useAuth();
  const { useToken } = theme;
  const { token } = useToken();
  return (
    <div>
      <Layout style={{ minHeight: '100vh' }}>
        <Header
          style={{
            height: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 20,
            backgroundColor: token.colorBgBlur,
            borderBottomWidth: 1,
            borderBottomColor: token.colorBorder,
            borderBottomStyle: 'solid',
          }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title level={1} style={{ /*color: token.colorText,*/ margin: 0, padding: 0 }}>CRONBE</Title>
            <span className="headerLinks" >
              <Space size='large' style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Button type="link" size="large" style={{ fontWeight: 'bold', color: token.colorTextDescription }} href='/'>Home</Button>
                <Button type="link" size="large" style={{ fontWeight: 'bold', color: token.colorTextDescription }} href='/#pricingSection'>Pricing</Button>
                <Button type="link" size="large" style={{ fontWeight: 'bold', color: token.colorTextDescription }} href='/#howDoesItWorkSection'>How does it work?</Button>
                <Button type="link" size="large" style={{ fontWeight: 'bold', color: token.colorTextDescription }} href='/#faqSection'>FAQ</Button>
                {/* <Button type="link" size="large" style={{ fontWeight: 'bold', color: token.colorTextDescription }}>Contact</Button> */}
              </Space >
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {
              isRefreshExpired() ?
                <Space size='middle'>
                  <Link to='/login' >
                    <Button type="primary" size="large" style={{ fontWeight: 'bold' }}>Login</Button>
                  </Link>
                  <Link to='/register'>
                    <Button type="primary" size="large" style={{ fontWeight: 'bold' }}>Subscribe</Button>
                  </Link>
                </Space>
                :
                <Link to='/jobs'>
                  <Button type="primary" size="large" style={{ fontWeight: 'bold' }}>Your Jobs</Button>
                </Link>
            }
          </div>
        </Header>
        <Content>

          <Row align="middle" justify="center">
            <Col xs={22} sm={20} md={18} lg={16} xl={12} xxl={10} >
              <Title level={1}>Terms of Service</Title>
              <Paragraph><i>Effective Date: October 20, 2024</i></Paragraph>
              <Paragraph>
                Welcome to Cronbe, an online platform monitoring health of your online services.
                By accessing or using our platform, you agree to comply with and be bound by these Terms of Service ("Terms").
                Please read these Terms carefully as they govern your use of the platform and constitute a legally binding agreement between you ("User") and Cronbe.
                If you do not agree to these Terms, you may not use the platform.
              </Paragraph>
              <Title level={2}>1. Definitions</Title>
              <Paragraph>
                For the purposes of these Terms, the following definitions shall apply:
              </Paragraph>
              <Paragraph>
                1.1 "<b>Platform</b>" refers to the website, mobile applications, and any other services offered by Cronbe, including any updates, modifications, or enhancements thereto.
              </Paragraph>
              <Paragraph>
                1.2 "<b>User</b>" refers to any individual or legal entity accessing or using the Platform.
              </Paragraph>
              <Paragraph>
                1.3 "<b>Services</b>" refer to the functionalities, tools, and services provided through the Platform, as described in more detail on the Cronbe website.
              </Paragraph>
              <Paragraph>
                1.4 "<b>Content</b>" refers to any data, text, images, videos, or other materials uploaded, posted, or made available on the Platform by the User or Cronbe.
              </Paragraph>
              <Title level={2}>2. Acceptance of Terms</Title>
              <Paragraph>
                By accessing or using the Platform, you affirm that you are at least 18 years old or, if applicable, are acting as a duly authorized representative of a legal entity.
                You further affirm that you have read, understood, and agree to be bound by these Terms.
              </Paragraph>
              <Title level={2}>3. User Accounts</Title>
              <Paragraph>
                3.1 <b>Account Creation:</b> To access certain features of the Platform, you may be required to create an account by providing accurate and complete registration information. You agree to keep such information up to date.
              </Paragraph>
              <Paragraph>
                3.2 <b>Account Responsibility:</b> You are solely responsible for maintaining the confidentiality of your account credentials and for any activities conducted under your account. Cronbe shall not be liable for any loss or damage arising from unauthorized use of your account.
              </Paragraph>
              <Paragraph>
                3.3 <b>Account Suspension or Termination:</b> Cronbe reserves the right to suspend or terminate your account at its sole discretion, without prior notice, for violation of these Terms or any applicable laws or regulations.
              </Paragraph>
              <Title level={2}>4. Use of the Platform</Title>
              <Paragraph>
                4.1 <b>Permissible Use:</b> You agree to use the Platform in compliance with all applicable laws and regulations,
                and for lawful purposes only. Any unlawful or unauthorized use of the Platform is strictly prohibited.
              </Paragraph>
              <Paragraph>
                4.2 <b>Prohibited Activities:</b> Users are prohibited from engaging in the following activities:
              </Paragraph>
              <ul>
                <li>Misusing the Platform by introducing viruses, Trojans, or other malicious code;</li>
                <li>Attempting to gain unauthorized access to the Platform’s systems or data;</li>
                <li>Infringing any intellectual property rights or other rights of Cronbe or third parties;</li>
                <li>Posting or transmitting content that is illegal, harmful, or violates the rights of others.</li>
              </ul>
              <Title level={2}>5. Fees and Payment</Title>
              <Paragraph>
                5.1 <b>Fees:</b> Cronbe reserves the right to charge fees for certain services or features of the Platform.
                Any applicable fees will be disclosed prior to your use of such services.
              </Paragraph>
              <Paragraph>
                5.2 <b>Payment Terms:</b> If you purchase any paid services on the Platform, you agree to provide
                accurate payment information and authorize Cronbe or its payment processor to charge you for all applicable fees.
                You are responsible for any taxes associated with your use of the Platform.
              </Paragraph>
              <Paragraph>
                5.3 <b>Refunds:</b> All payments are final, and Cronbe shall have no obligation to issue refunds,
                unless otherwise required by applicable law.
              </Paragraph>
              <Title level={2}>6. Intellectual Property</Title>
              <Paragraph>
                6.1 <b>Cronbe's Intellectual Property:</b> All intellectual property rights in and to the Platform,
                including but not limited to trademarks, copyrights, and patents, are owned by Cronbe or its licensors.
                You are granted a limited, non-exclusive, non-transferable license to access and use the Platform in accordance with these Terms.
              </Paragraph>
              {/* <Paragraph>
                6.2 <b>User Content:</b> By uploading or submitting any content to the Platform, you grant Cronbe a worldwide,
                royalty-free, non-exclusive license to use, reproduce, modify, distribute, and display such content for the purpose of providing and
                promoting the Platform. You represent and warrant that you have all necessary rights to grant this license.
              </Paragraph> */}
              <Title level={2}>7. Data Privacy and Security</Title>
              <Paragraph>
                7.1 <b>Data Collection and Use:</b> Cronbe collects and processes personal data in accordance with its <Link to='/legal/privacy-policy'>Privacy Policy</Link>,
                which is incorporated herein by reference. By using the Platform, you consent to such data collection and processing.
              </Paragraph>
              <Paragraph>
                7.2 <b>Data Security:</b> Cronbe implements appropriate technical and organizational measures to protect personal
                data against unauthorized access, destruction, loss, or alteration. However, you acknowledge that no data
                transmission over the internet can be guaranteed to be 100% secure.
              </Paragraph>
              <Title level={2}>8. Limitation of Liability</Title>
              <Paragraph>
                8.1 <b>No Warranty:</b> The Platform is provided "as is" and "as available," without any warranties of any kind,
                either express or implied, including but not limited to warranties of merchantability,
                fitness for a particular purpose, or non-infringement. Cronbe does not guarantee that the Platform will be error-free,
                secure, or uninterrupted.
              </Paragraph>
              <Paragraph>
                8.2 <b>Limitation of Liability:</b> To the maximum extent permitted by law, Cronbe shall not be liable for any indirect,
                incidental, special, consequential, or punitive damages, including but not limited to loss of profits,
                loss of data, or business interruption, arising out of or in connection with your use of the Platform.
              </Paragraph>
              <Paragraph>
                8.3 <b>Maximum Liability:</b> In any event, Cronbes total liability to you for any claims arising from your use of the Platform
                shall not exceed the amount paid by you to Cronbe, if any, in the 12 months preceding the claim.
              </Paragraph>
              <Title level={2}>9. Indemnification</Title>
              You agree to indemnify and hold harmless Cronbe, its affiliates, officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorney’s fees, arising out of or in connection with your use of the Platform, your breach of these Terms, or your violation of any third-party rights.

              <Title level={2}>10. Termination</Title>
              <Paragraph>
                10.1 <b>Termination by Cronbe:</b> Cronbe may terminate or suspend your access to the Platform at any time,
                with or without cause, and without prior notice.
              </Paragraph>
              <Paragraph>
                10.2 <b>Effect of Termination:</b> Upon termination, your right to use the Platform will immediately cease. Any provisions of these Terms that by their nature should survive termination shall do so, including but not limited to intellectual property provisions, disclaimers of warranties, and limitations of liability.
              </Paragraph>
              <Title level={2}>11. Governing Law and Jurisdiction</Title>
              <Paragraph>
                11.1 <b>Governing Law:</b> These Terms shall be governed by and construed in accordance with the laws of Poland, without regard to its conflict of law principles.
              </Paragraph>
              <Paragraph>
                11.2 <b>Dispute Resolution:</b> Any dispute arising out of or relating to these Terms shall be submitted to the exclusive jurisdiction of the courts of Poland.
              </Paragraph>
              <Title level={2}>12. Miscellaneous</Title>
              <Paragraph>
                12.1 <b>Entire Agreement:</b> These Terms constitute the entire agreement between you and Cronbe regarding your use of the Platform and supersede any prior agreements or understandings, whether written or oral.
              </Paragraph>
              <Paragraph>
                12.2 <b>Severability:</b> If any provision of these Terms is found to be unlawful, void, or unenforceable, such provision shall be deemed severable and shall not affect the validity and enforceability of the remaining provisions.
              </Paragraph>
              <Paragraph>
                12.3 <b>Waiver:</b> No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and Cronbe’s failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.
              </Paragraph>
              <Title level={2}>13. Changes to Terms</Title>
              <Paragraph>
                Cronbe reserves the right to modify these Terms at any time. Any changes will be effective upon posting on the Platform. Your continued use of the Platform following the posting of revised Terms constitutes your acceptance of such changes.
              </Paragraph>
              <Paragraph>
                For any questions or clarifications regarding these Terms, please contact us at contact@cronbe.com.
              </Paragraph>
              <Title level={2}>14. Contact Information</Title>
              <Paragraph>
                Dariusz Szarejko<br/>
                Gustawa Gizewiusza 4/6/20<br/>
                19-300 Ełk<br/>
                Poland<br/>
                Email: contact@cronbe.com
              </Paragraph>

            </Col>
          </Row>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
        <Space><Text>CRONBE © 2024</Text> | <Link to='/legal/terms-of-service'>Terms of Service</Link> | <Link to='/legal/privacy-policy'>Privacy Policy</Link></Space>
        </Footer>
      </Layout>
    </div>
  );
}

export default TermsOfService;
