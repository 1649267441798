const PROTOCOL = process.env.REACT_APP_API_PROTOCOL;
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

const apiconfig = {
  // JWT TOKENS
  TOKEN_CREATE: PROTOCOL + '://' + API_DOMAIN + '/auth/jwt/create/',
  TOKEN_REFRESH: PROTOCOL + '://' + API_DOMAIN + '/auth/jwt/refresh/',
  TOKEN_VERIFY: PROTOCOL + '://' + API_DOMAIN + '/auth/jwt/verify/',
  // API_TOKEN_BLACKLIST: PROTOCOL + '://' + API_DOMAIN + '/api/token/blacklist/',

  // JOBS
  API_JOBS: PROTOCOL + '://' + API_DOMAIN + '/tasks/jobs/',
  API_JOBS_RESULTS: PROTOCOL + '://' + API_DOMAIN + '/tasks/jobs/results/',
  API_JOBS_FAIL_SUCCESS_COUNTER: PROTOCOL + '://' + API_DOMAIN + '/tasks/jobs/failsuccesscounter/',

  // USERS
  USER_CREATE: PROTOCOL + '://' + API_DOMAIN + '/auth/users/',
  USER_ACTIVATE: PROTOCOL + '://' + API_DOMAIN + '/auth/users/activation/',
  USER_REQUEST_PASSWORD_RESET: PROTOCOL + '://' + API_DOMAIN + '/auth/users/reset_password/',
  USER_RESET_PASSWORD_CONFIRM: PROTOCOL + '://' + API_DOMAIN + '/auth/users/reset_password_confirm/',
  USER_DATA: PROTOCOL + '://' + API_DOMAIN + '/accounts/user-data/',

  // PAYMENT
  STRIPE_CREATE_CHECKOUT_SESSION: PROTOCOL + '://' + API_DOMAIN + '/payment/stripe-create-checkout-session/',
  STRIPE_CREATE_CUSTOMER_PORTAL_SESSION: PROTOCOL + '://' + API_DOMAIN + '/payment/stripe-create-customer-portal-session/'
};

export default apiconfig;