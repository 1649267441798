import { axiosPrivate } from "../api/axiosServices";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { HTTP_401_UNAUTHORIZED } from "../utils/http_codes";

const useAxiosPrivate = () => {
    const refresh = useRefreshToken();
    const { accessToken } = useAuth();

    useEffect(() => {
        // REQUEST INTERCEPTOR
        const requestIntercept = axiosPrivate.interceptors.request.use(
            (config) => {
                if (accessToken && !config._retry) {
                    // accessToken cheges only between two useEffect calls 
                    config.headers.Authorization = `Bearer ${accessToken}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        )
        // RESPONSE INTERCEPTOR
        const responseIntercept = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === HTTP_401_UNAUTHORIZED && !prevRequest?._retry) {
                    prevRequest._retry = true;
                    const newAccessToken = await refresh();
                    prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                    return axiosPrivate(prevRequest);
                }
                return Promise.reject(error)
            }
        );
        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        }
    }, [refresh])

    return axiosPrivate;
}

export default useAxiosPrivate;