import { DashboardOutlined, LockOutlined, MobileOutlined } from '@ant-design/icons';
import { Button, Col, Layout, List, Row, Slider, Space, Typography, theme } from 'antd';
import React, { useState } from 'react';
import { BsEnvelopeExclamation } from "react-icons/bs";
import { HiOutlineDocumentMagnifyingGlass, HiOutlineGlobeAlt } from "react-icons/hi2";
import { LuCalendarClock } from "react-icons/lu";
import { MdHttp, MdPowerSettingsNew, MdQuestionMark } from "react-icons/md";
import { Link } from "react-router-dom";
import ProductFeature from "../components/ProductFeature";
import useAuth from "../hooks/useAuth";

const { Header, Content, Footer } = Layout;
const { Title, Paragraph, Text } = Typography;

const faq_list_data = [
  {
    title: 'How does CRONBE works?',
    description: 'CRONBE is a cloud-based service that automates the execution of cron jobs to monitor your web presence effectively. Simply specify a schedule using either detailed cron expressions or straightforward time intervals, along with a target URL. CRONBE will handle the rest by sending HTTP requests to the URL according to your specified timetable.',
  },
  {
    title: 'Can I monitor and review the performance of my cron jobs?',
    description: 'Absolutely. CRONBE provides detailed logs and performance metrics. You can check the timing, duration, server responses, and status codes of each job execution. This enables you to monitor job effectiveness and quickly address any issues.',
  },
  {
    title: 'How flexible is job scheduling in CRONBE?',
    description:
      <>
        <Text>CRONBE offers versatile scheduling options:</Text>
        <ul>
          <li>Cron Expressions: Ideal for complex, specific timing needs.</li>
          <li>Simple Intervals: Best for regular, repeated job execution. This allows precise control over how and when your jobs run, tailored to your operational requirements.</li>
        </ul>
      </>,
  },
  {
    title: 'What customization options are available for HTTP requests?',
    description: 'You can fully customize HTTP methods and headers in CRONBE. Choose from methods like GET, POST, PUT, PATCH, DELETE, HEAD, and OPTIONS, and tailor headers and payloads to meet your specific needs, especially for methods that require body data.',
  },
  {
    title: 'Does CRONBE support scheduling jobs across different timezones?',
    description: 'Yes, CRONBE allows each cron job to be independently set to any timezone, facilitating seamless scheduling for global operations without the hassle of timezone conversions.',
  }
];

const LandingPage = () => {
  const { isRefreshExpired } = useAuth();
  const pageMaxWidth = '1300px';
  const { useToken } = theme;
  const { token } = useToken();
  const band_colors = [token.colorBgBlur, token.colorFillContent];
  const [nbOfJobsState, setNbOfJobsState] = useState(1);

  const marks = {
    1: { label: <strong>1 Job</strong>, style: { color: '#028066' } },
    10: { label: <strong>10 Jobs</strong>, style: { color: '#339966' } },
    // 15: { label: <strong>15 Jobs</strong>, style: { color: '#269266' } },
    20: { label: <strong>20 Jobs</strong>, style: { color: '#339966' } },
    30: { label: <strong>30 Jobs</strong>, style: { color: '#4ca566' } },
    50: { label: <strong>50 Jobs</strong>, style: { color: '#80bf66' } },
    70: { label: <strong>70 Jobs</strong>, style: { color: '#b3d966' } },
    100: { label: <strong>∞</strong>, style: { color: '#ffff66' } },
  };


  return (
    <div>
      <Layout style={{ minHeight: '100vh' }}>
        <Header
          style={{
            height: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 20,
            backgroundColor: token.colorBgBlur,
            borderBottomWidth: 1,
            borderBottomColor: token.colorBorder,
            borderBottomStyle: 'solid',
          }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title level={1} style={{ /*color: token.colorText,*/ margin: 0, padding: 0 }}>CRONBE</Title>
            <span className="headerLinks" >
              <Space size='large' style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Button type="link" size="large" style={{ fontWeight: 'bold', color: token.colorTextDescription }}>Home</Button>
                <Button type="link" size="large" style={{ fontWeight: 'bold', color: token.colorTextDescription }} href='#pricingSection'>Pricing</Button>
                <Button type="link" size="large" style={{ fontWeight: 'bold', color: token.colorTextDescription }} href='#howDoesItWorkSection'>How does it work?</Button>
                <Button type="link" size="large" style={{ fontWeight: 'bold', color: token.colorTextDescription }} href='#faqSection'>FAQ</Button>
                {/* <Button type="link" size="large" style={{ fontWeight: 'bold', color: token.colorTextDescription }}>Contact</Button> */}
              </Space >
            </span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {
              isRefreshExpired() ?
                <Space size='middle'>
                  <Link to='/login' >
                    <Button type="primary" size="large" style={{ fontWeight: 'bold' }}>Login</Button>
                  </Link>
                  <Link to='/register'>
                    <Button type="primary" size="large" style={{ fontWeight: 'bold' }}>Subscribe</Button>
                  </Link>
                </Space>
                :
                <Link to='/jobs'>
                  <Button type="primary" size="large" style={{ fontWeight: 'bold' }}>Your Jobs</Button>
                </Link>
            }
          </div>
        </Header>
        <Content>
          <div
            style={{
              paddingTop: '80px',
              paddingBottom: '80px',
              paddingLeft: '20px',
              paddingRight: '20px',
              textAlign: 'center',
              margin: 0,
              background: band_colors[0],
            }}>
            <div
              style={{
                maxWidth: pageMaxWidth,
                margin: '0 auto',
              }}>
              <Title
                level={1}
                style={{ fontSize: '40px', marginTop: 0 }}
              >
                Web Watchdog: Keeping Your Services Up, Effortlessly
              </Title>
              <Paragraph style={{ fontSize: '20px' }}>
                Effortlessly monitor your web services with our simple setup. Stay ahead of failures and keep your digital presence strong.
              </Paragraph>
              <Link to='/register'>
                <Button type="primary" size="large" style={{ fontSize: '20px', height: '50px' }}>Get Started for Free</Button>
              </Link>
            </div>
          </div>
          {/* ---------------------------------------- */}
          <div
            style={{
              paddingTop: '40px',
              paddingBottom: '40px',
              paddingLeft: '20px',
              paddingRight: '20px',
              margin: 0,
              textAlign: 'center',
              background: band_colors[1],
            }}>
            <div
              style={{
                maxWidth: pageMaxWidth,
                margin: '0 auto',
              }}>
              <Title
                id='howDoesItWorkSection'
                level={1}
                style={{ marginTop: 0 }}
              >
                How does it work ?
              </Title>
              <Paragraph style={{ maxWidth: '1000px', margin: 'auto', textAlign: 'left', fontSize: '18px', }}>
                Our platform ensures your scheduled tasks, such as cron jobs, backups, and automated processes, run <strong>seamlessly</strong>.
                By integrating with your systems, we <strong>monitor task executions</strong> in real time, providing <strong>instant alerts</strong> for failures,
                delays, or missed schedules via Email, Discord, Slack and much more with webhooks.
              </Paragraph>
              <br></br>
              <Paragraph style={{ maxWidth: '1000px', margin: 'auto', textAlign: 'left', fontSize: '18px', }}>
                Simply configure your tasks, and our app will track their health, notifying you instantly of any issues. This ensures your <strong>critical processes</strong> stay on track,
                avoiding costly downtime or unnoticed failures. Whether you're managing one or many tasks, our service keeps you <strong>informed and in control</strong>.
              </Paragraph>
            </div>
          </div>
          {/* ---------------------------------------- */}
          <div
            style={{
              paddingTop: '40px',
              paddingBottom: '40px',
              paddingLeft: '20px',
              paddingRight: '20px',
              margin: 0,
              textAlign: 'center',
              background: band_colors[0],
            }}>
            <div
              style={{
                maxWidth: pageMaxWidth,
                margin: '0 auto',
              }}>
              <Title level={1}>What's inside?</Title>
              <Row justify="center" gutter={[16, 16]} style={{ marginBottom: 0 }}>
                <Col >
                  <ProductFeature
                    icon={<LuCalendarClock />}
                    iconColor={token.colorPrimary}
                    title='Effortless Scheduling'
                    text='Choose flexibility in scheduling with either standard cron expressions or simple intervals, for precise control over your tasks.' />
                </Col>
                <Col >
                  <ProductFeature
                    icon={<HiOutlineDocumentMagnifyingGlass />}
                    iconColor={token.colorPrimary}
                    title='Comprehensive Monitoring'
                    text='Access real-time insights with detailed execution logs, response times, and status codes, all in one place.' />
                </Col>
                <Col >
                  <ProductFeature
                    icon={<DashboardOutlined />}
                    iconColor={token.colorPrimary}
                    title='Insightful Metrics'
                    text='Track job performance through clear metrics on successes and failures, defining success by swift and accurate server responses.' />
                </Col>
                <Col >
                  <ProductFeature
                    icon={<LockOutlined />}
                    iconColor={token.colorPrimary}
                    title='Seamless Authentication'
                    text='Simplify security with Basic Authentication setup for jobs, ensuring each request is both safe and authenticated.' />
                </Col>
                <Col >
                  <ProductFeature
                    icon={<MdHttp />}
                    iconColor={token.colorPrimary}
                    title='Customizable Requests'
                    text='Tailor your HTTP requests with custom headers and methods, including advanced options like GET, POST, and PATCH, for precise web interactions.' />
                </Col>
                <Col >
                  <ProductFeature
                    icon={<BsEnvelopeExclamation />}
                    iconColor={token.colorPrimary}
                    title='Real-Time Alerts'
                    text='Receive timely email alerts on job failures, with customizable thresholds for consecutive failures, keeping you informed and proactive.' />
                </Col>
                <Col >
                  <ProductFeature
                    icon={<HiOutlineGlobeAlt />}
                    iconColor={token.colorPrimary}
                    title='Global Timezone Support'
                    text='Schedule jobs accurately across any timezone, granting you the flexibility to operate on a global scale without hassle.' />
                </Col>
                <Col >
                  <ProductFeature
                    icon={<MdPowerSettingsNew />}
                    iconColor={token.colorPrimary}
                    title='Job Control'
                    text='Effortlessly enable or disable jobs to optimize their performance and manage your resources effectively.' />
                </Col>
                <Col >
                  <ProductFeature
                    icon={<MobileOutlined />}
                    iconColor={token.colorPrimary}
                    title='Intuitive Interface'
                    text='Navigate with ease using our clean, responsive interface, designed for both dark and light modes and mobile-friendly for on-the-go access.' />
                </Col>
              </Row>
            </div>
          </div>
          {/* ---------------------------------------- */}
          <div
            style={{
              paddingTop: '40px',
              paddingBottom: '40px',
              paddingLeft: '20px',
              paddingRight: '20px',
              margin: 0,
              textAlign: 'center',
              background: band_colors[1],
            }}>
            <div
              style={{
                maxWidth: pageMaxWidth,
                margin: '0 auto',
              }}>
              <Title
                id='pricingSection'
                level={1}
                style={{ marginTop: 0 }}
              >
                Pricing made simple
              </Title>
              {/* <Title level={3}>Simple pricing $2 / job / month</Title> */}
              <Title style={{ margin: '30px', padding: 0, color:token.colorPrimary }}>$ {2 * nbOfJobsState} / month</Title>
              <Row justify='center'>
                <Col span={22}>
                  <Slider
                    marks={marks}
                    min={1}
                    max={99}
                    defaultValue={nbOfJobsState}
                    onChange={(nb) => {nb > 99 ? setNbOfJobsState(99) : setNbOfJobsState(nb);}} 
                  />
                </Col>
              </Row>
            </div>
          </div>
          {/* ---------------------------------------- */}
          <div
            style={{
              paddingTop: '40px',
              paddingBottom: '40px',
              paddingLeft: '20px',
              paddingRight: '20px',
              margin: 0,
              textAlign: 'center',
              background: band_colors[0],
            }}>
            <div
              style={{
                maxWidth: pageMaxWidth,
                margin: '0 auto',
              }}>
              <Title
                id='faqSection'
                align='center'
                level={1}
                style={{ marginTop: 0 }}
              >
                FAQ
              </Title>
              <div style={{ textAlign: 'left' }}>
                <List
                  itemLayout="horizontal"
                  dataSource={faq_list_data}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Text style={{ fontSize: '24px', }}><MdQuestionMark /></Text>}
                        title={<Title level={3}>{item.title}</Title>}
                        description={<Text>{item.description}</Text>}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </div>
          {/* ---------------------------------------- */}
          <div
            style={{
              paddingTop: '40px',
              paddingBottom: '40px',
              paddingLeft: '20px',
              paddingRight: '20px',
              margin: 0,
              textAlign: 'center',
              background: band_colors[1],
            }}>
            <div
              style={{
                maxWidth: pageMaxWidth,
                margin: '0 auto',
              }}>
              <Title style={{ marginTop: 0 }}>Get Your First Scheduler Running in Under a Minute</Title>
              <Link to='/register'>
                <Button type="primary" size="large" style={{ fontSize: '20px', height: '50px' }}>Set Up Now</Button>
              </Link>
            </div>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          
          <Space><Text>CRONBE © 2024</Text> | <Link style={{ color: token.colorText }} to='/legal/terms-of-service'>Terms of Service</Link> | <Link style={{ color: token.colorText }} to='/legal/privacy-policy'>Privacy Policy</Link></Space>
        </Footer>
      </Layout>
    </div >
  );
}
export default LandingPage;
