import { CaretRightOutlined, DeleteOutlined, EditOutlined, PauseOutlined } from '@ant-design/icons';
import { Button, Select, Tag } from 'antd';


function PrepareTableData(input_list, enabledClick, editClick, deleteClick, logsClick) {
    /* CHECK INPUT DATA */
    if (!Array.isArray(input_list))
        throw new TypeError("Input should be a list!")
    const obj_keys = [
        'id',
        'user_job_name',
        'schedule_type',
        'http_url',
        'http_method',
        'http_auth_user',
        'http_auth_passwd',
        'http_headers',
        'http_payload',
        'enabled',
        'email_notify',
        'email_notify_fail_nb',
        'timezone',
        'timeout',
        'total_run_count'
    ]

    input_list.forEach(obj => {
        for (let key in obj_keys) {
            if (!obj.hasOwnProperty(obj_keys[key])) {
                throw new ReferenceError('Key "' + obj_keys[key] + '" is missing!');
            }
        }
    });

    /* SELECT */
    const options = [
        {
            label: <><CaretRightOutlined />{"   "}Enable</>,
            value: 'enable',
        },
        {
            label: <><PauseOutlined />{"   "}Disable</>,
            value: 'disable',
        },
        {
            label: <><EditOutlined />{"   "}Edit</>,
            value: 'edit',
        },
        {
            label: <><DeleteOutlined />{"   "}Delete</>,
            value: 'delete',
        }
    ]
    const onSelect = async (e, id) => {
        switch (e) {
            case 'enable':
                enabledClick(id, true)
                break;
            case 'disable':
                enabledClick(id, false)
                break;
            case 'edit':
                editClick(id)
                break;
            case 'delete':
                deleteClick(id)
                break;
        }
    }

    /* PREPARE DATA FOR THE TABLE */
    const mapping = new Map([
        ['minutes', 'min'],
        ['hours', 'h'],
        ['days', 'd'],
        ['years', 'y']
    ])
    let table_list = []
    input_list.forEach(input_obj => {
        let cron_or_interval = ""
        if (input_obj.schedule_type === "interval") {
            const every = input_obj.interval.every
            const period = mapping.get(input_obj.interval.period) || input_obj.interval.period
            cron_or_interval = every + ' ' + period
        }
        else if (input_obj.schedule_type === "cron") {
            const minute = input_obj.crontab.minute
            const hour = input_obj.crontab.hour
            const day_of_week = input_obj.crontab.day_of_week
            const day_of_month = input_obj.crontab.day_of_month
            const month_of_year = input_obj.crontab.month_of_year
            cron_or_interval = minute + hour + day_of_week + day_of_month + month_of_year
        }

        let table_obj = {
            "id": input_obj.id,
            "user_job_name": input_obj.user_job_name,
            "schedule_type": input_obj.schedule_type,
            "cron_or_interval": cron_or_interval,
            "http_url": input_obj.http_url,
            "http_method": <Tag>{input_obj.http_method}</Tag>,
            "logs":
                <>
                    <Button onClick={(e) => { logsClick({ id: input_obj.id, user_job_name: input_obj.user_job_name }) }} >Logs</Button>
                </>,
            "actions":
                <>
                    <Select
                        value={null}
                        style={{ width: 120, }}
                        placeholder="Action"
                        options={options}
                        onSelect={(e) => { onSelect(e, input_obj.id) }}
                    />
                </>,
            "enabled": input_obj.enabled === true ? <CaretRightOutlined /> : <PauseOutlined />,
            // "email_notify": true,
            // "email_notify_fail_nb": 3,
            "timezone": input_obj.timezone,
            // "timeout": input_obj.timeout,
            "total_run_count": input_obj.total_run_count
        }
        table_list.push(table_obj)
    });

    table_list.sort((a, b) => a.id > b.id ? 1 : -1)

    return table_list
}
export default PrepareTableData;