import { Typography } from 'antd';
import React from 'react';
const { Title, Text } = Typography;

function ProductFeature({ title, text, icon, iconColor }) {
    const card_height = '210px';
    const card_width = '350px';

    return (
        <>
            <div
                style={{
                    height: card_height,
                    width: card_width,
                    padding: '10px',
                    justifyContent: 'center',
                    justifyItems: 'center',
                }}>
                <div
                    style={{
                        height: '60px',
                        textAlign: 'left',
                    }}
                >
                    <Text style={{ color: iconColor, fontSize: '40px', margin: 0, padding: 0 }}>{icon}</Text>
                </div>
                <div
                    style={{
                        height: '50px',
                        width: card_width,
                        textAlign: 'left',
                        margin: '0 auto'
                    }}>
                    <Title level={3} style={{ margin: 0, padding: 0 }}>{title}</Title>
                </div>
                <div
                    style={{
                        height: '100px',
                        width: card_width,
                        paddingRight: '10px',
                        textAlign: 'left',
                        margin: '0 auto'
                    }}>
                    <Text style={{ margin: 0, paddingRight: '10px' }}>{text}</Text>
                </div>
            </div>
        </>
    );
}
export default ProductFeature;
