import { React, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import apiconfig from '../apiconfig';
import HeaderBar from '../components/HeaderBar';
import Sidebar from '../components/Sidebar';
import useAuth from '../hooks/useAuth';

// REACT ROUTER DOM
import { Link, useNavigate } from "react-router-dom";


// ANT DESIGN
import {
    CreditCardOutlined,
    SettingOutlined,
    ThunderboltOutlined
} from '@ant-design/icons';

import { Layout, Menu } from 'antd';

// PROJECT

const { Content } = Layout;

const menu_items = [
    {
        type: null,
        key: 'jobs',
        label: <Link to='/jobs'>Jobs</Link>,
        icon: <ThunderboltOutlined />,
        children: null,
    },
    {
        type: 'group',
        key: 'settings_grp',
        label: 'Settings',
        icon: null,
        children: [
            {
                type: null,
                key: 'settings',
                label: <Link to='/settings'>Settings</Link>,
                icon: <SettingOutlined />,
                children: null,
            },
            {
                type: null,
                key: 'subscription',
                label: <Link to='/subscription'>Subscription</Link>,
                icon: <CreditCardOutlined />,
                children: null,
            },
        ],
    }
];

const ProtectedLayout = () => {

    /* AUTHORIZATION CONTEXT */
    const { authTokens } = useAuth();

    /* STATE OF THE MENU */
    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawerOpen = () => { setDrawerOpen(!drawerOpen); };
    const [siderCollapsed, setSiderCollapsed] = useState(false);
    const toggleSiderCollapsed = () => { setSiderCollapsed(!siderCollapsed); };

    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const selectedKey = pathname.split('/')[1] || 'dashboard';

    /* LOGOUT HANDLER */
    let onClick = async ({ item, key, keyPath, domEvent }) => {

        if (drawerOpen)
            toggleDrawerOpen();

        if (key === 'logout') {

            let response = await fetch(apiconfig.API_TOKEN_BLACKLIST,
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', },
                    body: JSON.stringify({ 'refresh': authTokens.refresh })
                })

            if (response.status === 200) {
                localStorage.removeItem('authTokens');
                navigate('/login');
            }
            else {
                console.error('Can\'t logout!');
            }
        }
    }
    const menu = (
        <Menu
            selectedKeys={[selectedKey]}
            defaultOpenKeys={[]}
            mode="inline"
            items={menu_items}
            style={{
                height: '100%',
                fontWeight: 'bold',
                borderInlineEnd: 'unset'
            }}
            onClick={onClick}
        />
    )

    return (
        <>
            <Layout>
                <HeaderBar menu={menu} toggleSiderCollapsed={toggleSiderCollapsed} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
                <Layout style={{ minHeight: '100vh', }} >
                    <Sidebar menu={menu} siderCollapsed={siderCollapsed} />
                    <Content
                        style={{
                            margin: '0px 0px 0px 0px',
                            padding: '0 5% 50px 5%',
                        }}>
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </>);
};

export default ProtectedLayout;
