import jwt_decode from 'jwt-decode';
import { createContext, useState } from 'react';
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
    // Check if ther is a token if it is read it

    const [authTokens, setAuthTokens] = useState(localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null);
    const [user, setUser] = useState(localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null);
    const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null);
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken') ? JSON.parse(localStorage.getItem('refreshToken')) : null);
    const [userProfile, setUserProfile] = useState(localStorage.getItem('userProfile') ? JSON.parse(localStorage.getItem('userProfile')) : null);

    const login = (json_response) => {

        setAuthTokens(json_response);
        setUser(jwt_decode(json_response.access));

        setAccessToken(json_response.access);
        setRefreshToken(json_response.refresh);

        localStorage.setItem('authTokens', JSON.stringify(json_response));
        localStorage.setItem('accessToken', JSON.stringify(json_response.access));
        localStorage.setItem('refreshToken', JSON.stringify(json_response.refresh));
    }

    const logout = () => {
        setAuthTokens(null);
        setUser(null);
        setAccessToken(null);
        setRefreshToken(null);
        setUserData(null);

        localStorage.removeItem('authTokens');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userProfile');
    }

    const repleaceAccesToken = (newAccessToken) => {
        setAuthTokens(prev => { return { ...prev, access: newAccessToken } });
        setAccessToken(newAccessToken);

        localStorage.setItem('authTokens', JSON.stringify(authTokens));
        localStorage.setItem('accessToken', JSON.stringify(newAccessToken));
    }

    const isRefreshExpired = (token) => {
        try {
            const decoded = jwt_decode(localStorage.getItem('refreshToken'));
            const expTime = decoded.exp * 1000;
            return Date.now() > expTime;
        } catch (error) {
            // console.error('Failed to decode or check token expiration', error);
            return true; // Assume expired if there's any issue
        }
    }

    const setUserData = (user_data) => {
        setUserProfile(user_data);
        localStorage.setItem('userProfile', JSON.stringify(user_data));
    }

    let contextData = {
        authTokens: authTokens,
        user: user,
        accessToken: accessToken,
        refreshToken: refreshToken,
        setAccessToken: setAccessToken,
        repleaceAccesToken: repleaceAccesToken,
        isRefreshExpired: isRefreshExpired,
        setUserData: setUserData,
        userProfile: userProfile,
        login: login,
        logout: logout,
    }

    return (
        <AuthContext.Provider value={contextData} >
            {children}
        </AuthContext.Provider>
    )
}