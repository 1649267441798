import { Button, Card, Checkbox, Col, Form, Input, Layout, Row, Typography, message } from 'antd';
import axios from "axios";
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import apiconfig from '../apiconfig';
import { HTTP_201_CREATED } from '../utils/http_codes';
const { Title } = Typography;
const { Item } = Form;

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

const RegisterPage = () => {
    const [form] = Form.useForm();
    const [formEmail, setFormEmail] = useState('');
    const [formName, setFormName] = useState('');
    const [formPassword, setFormPassword] = useState('');
    const [formRePassword, setFormRePassword] = useState('');
    const [formAccept, setFormAccept] = useState('');
    const [registerButtonLoading, setRegisterButtonLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const creationFailed = () => {
        messageApi.open({
            type: "error",
            content: "Can't create the user. Something went wrong.",
            duration: 10,
            maxCount: 10,
        });
    };
    const creationSucceeded = () => {
        messageApi.open({
            type: "success",
            content: "Account created. Check your mailbox.",
            duration: 0,
            maxCount: 10,
        });
    };

    const emailRef = useRef();
    useEffect(() => {
        emailRef.current.focus();
    }, []);
    const validatePassword = (_, value) => {

        if (value && passwordRegex.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject('Minimum 8 characters, at least: 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.');

    }

    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setFormPassword(value);
        form.validateFields(['re_password']);
    };

    const handleRePasswordChange = (e) => {
        const { value } = e.target;
        setFormRePassword(value);
    };

    const handleOnFinish = async (e) => {
        setRegisterButtonLoading(true)
        try {
            const response = await axios.post(apiconfig.USER_CREATE,
                {
                    "email": e.email,
                    "first_name": e.name,
                    "password": e.password,
                    "re_password": e.re_password,
                    "accept": e.accept
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                })

            if (response.status === HTTP_201_CREATED) {
                setFormEmail('')
                setFormName('')
                setFormPassword('')
                setFormRePassword('')
                setFormAccept('')
                setRegisterButtonLoading(false)
                creationSucceeded()
            }

        } catch (err) {
            creationFailed()
            setRegisterButtonLoading(false)
            console.log(err)
        }
    }

    return (
        <div>
            <Layout style={{ justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                {contextHolder}
                <Row justify="center" style={{ width: '100%' }} >
                    <Col xs={22} sm={20} md={18} lg={16} xl={12} xxl={8} >
                        <Card style={{ margin: 0 }}>
                            <center><Title>Register</Title></center>
                            <Form
                                form={form}
                                layout='vertical'
                                initialValues={{ remember: false, }}
                                onFinish={handleOnFinish}
                                onFinishFailed={null}
                                autoComplete='off'
                            >
                                <Item
                                    name='email'
                                    label='Email'
                                    wrapperCol={{ offset: 0, span: 24, }}
                                    rules={[
                                        { type: 'email', message: 'The input is not a valid email!', },
                                        { required: true, message: 'Please input your email!', },]}
                                >
                                    <Input
                                        size='large'
                                        ref={emailRef}
                                        value={formEmail}
                                        placeholder='Your email'
                                        onChange={null}
                                    />
                                </Item>
                                <Item
                                    name='name'
                                    label='Name'
                                    wrapperCol={{ offset: 0, span: 24, }}
                                    rules={[{ required: true, message: 'Please input your name!', },]}
                                >
                                    <Input
                                        size='large'
                                        ref={null}
                                        value={formName}
                                        placeholder='Your name'
                                        onChange={null}
                                    />
                                </Item>
                                <Item
                                    name='password'
                                    label='Password'
                                    wrapperCol={{ offset: 0, span: 24, }}
                                    rules={[
                                        { required: true, message: 'Please input your password!', },
                                        { validator: validatePassword }]}
                                >
                                    <Input.Password
                                        size='large'
                                        ref={null}
                                        value={formPassword}
                                        placeholder='Password'
                                        onChange={handlePasswordChange}
                                    />
                                </Item>
                                <Item
                                    name='re_password'
                                    label='Confirm password'
                                    wrapperCol={{ offset: 0, span: 24, }}
                                    rules={[
                                        { required: true, message: 'Please input your password!', },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The new password that you entered do not match!'));
                                            },
                                        })]}
                                >
                                    <Input.Password
                                        size='large'
                                        ref={null}
                                        value={formRePassword}
                                        placeholder='Retype password'
                                        onChange={handleRePasswordChange}
                                    />
                                </Item>
                                <Item
                                    name='accept'
                                    valuePropName='checked'
                                    value={formAccept}
                                    wrapperCol={{ span: 24, }}
                                    rules={[{ required: true, message: 'Accept terms and privacy policy' },]}
                                >
                                    <Checkbox>I accept <Link to='/legal/terms-of-service'>the terms</Link> and <Link to='/legal/privacy-policy'>privacy policy</Link>.</Checkbox>
                                </Item>

                                <Item
                                    wrapperCol={{ offset: 0, span: 24, }}
                                >
                                    <Button
                                        block
                                        size='large'
                                        htmlType='submit'
                                        type='primary'
                                        loading={registerButtonLoading}
                                    >
                                        Register
                                    </Button>
                                </Item>
                            </Form>
                            <p>Already have an account? <Link to='/login'>Sign In</Link></p>
                        </Card>
                    </Col>
                </Row>
            </Layout>
        </div>
    )
}

export default RegisterPage;