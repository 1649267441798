import { Button, Card, Form, Input, Layout, Space, Typography, message } from 'antd';
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import apiconfig from "../apiconfig";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { HTTP_200_OK } from '../utils/http_codes';

const { Title } = Typography;
const { Item } = Form;

const LoginPage = () => {
    const { login, logout, setUserData } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/jobs";

    const userRef = useRef();

    const [formUser, setFormUser] = useState('');
    const [formPasswd, setFormPasswd] = useState('');
    const [loginButtonLoading, setLoginButtonLoading] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();
    const loginFailed = () => {
        messageApi.open({
            type: "error",
            content: "Incorrect username or password. Please try again.",
        });
    };

    useEffect(() => {
        userRef.current.focus();
    }, []);

    const onFinishFailed = (errorInfo) => {
        setLoginButtonLoading(false);
        console.error('Failed:', errorInfo);
    };

    const handleOnFinish = async (e) => {
        setLoginButtonLoading(true);
        try {
            const response = await axios.post(apiconfig.TOKEN_CREATE,
                {
                    "email": e.email,
                    "password": e.password
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                });
            if (response.status === HTTP_200_OK) {
                setLoginButtonLoading(false);
                setFormUser('');
                setFormPasswd('');
                await login(response.data);

                // GET USER DATA
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${response.data?.access}`
                    }
                };
                try {
                    const user_data = await axiosPrivate.get(apiconfig.USER_DATA, config);
                    setUserData(user_data.data);
                } catch (err) {
                    console.error("User data not avaiable.", err);
                    await logout();
                    return;
                }

                // GO TO '/jobs'
                navigate(from, { replace: true });
            }
        } catch (err) {
            setLoginButtonLoading(false);
            loginFailed();
            console.error(err);
        }
    }

    return (
        <div>
            <Layout style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                <Space align="center">
                    <Card>
                        <center><Title>Login</Title></center>
                        {contextHolder}
                        <Form
                            onFinish={handleOnFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Item
                                label=""
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    },
                                ]}
                            >
                                <Input
                                    ref={userRef}
                                    value={formUser}
                                    placeholder="Email"
                                    onChange={(e) => setFormUser(e.target.value)}
                                    size='large'
                                />
                            </Item>

                            <Item
                                label=""
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                            >
                                <Input.Password
                                    placeholder="Password"
                                    value={formPasswd}
                                    onChange={(e) => setFormPasswd(e.target.value)}
                                    size='large'
                                />
                            </Item>

                            <Item>
                                <Button loading={loginButtonLoading} block type="primary" htmlType="submit">
                                    Login
                                </Button>
                            </Item>
                        </Form>
                        <p>Need an Account? <Link to='/register'>Sign Up</Link></p>
                        <p>Forgot your Password? <Link to='/reset_password'>Reset Password</Link></p>
                    </Card>
                </Space>
            </Layout>
        </div>
    );
}

export default LoginPage;

