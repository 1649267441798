import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { refreshToken, isRefreshExpired } = useAuth();
    const location = useLocation();
    return (
        isRefreshExpired(refreshToken) ? <Navigate to="/login" state={{ from: location }} replace /> : <Outlet />
    );
}
export default RequireAuth;