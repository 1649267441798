import { Button, Card, Layout, Typography } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import apiconfig from "../apiconfig";
import { HTTP_204_NO_CONTENT } from "../utils/http_codes";


const { Title } = Typography;

const UserActivation = () => {
    const [userActivated, setUserActivated] = useState(false);
    const { uid, token } = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/login";

    const activateAccount = async (e) => {
        const body = JSON.stringify({ uid, token })
        const config = { headers: { 'Content-Type': 'application/json' } }

        try {
            let response = await axios.post(apiconfig.USER_ACTIVATE, body, config)

            if (response.status === HTTP_204_NO_CONTENT) {
                setUserActivated(true)
            }
        }
        catch (err) {
            setUserActivated(false)
        }
    }

    if (userActivated) {
        navigate(from, { replace: true })
    }

    return (
        <div>
            <Layout style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                <Card>
                    <Title level={1}>Click to verify your account</Title>
                    <Button
                        block
                        size="large"
                        onClick={activateAccount}
                        type='primary'
                    >
                        Verify
                    </Button>
                </Card>
            </Layout>
        </div>
    )
}
export default UserActivation;