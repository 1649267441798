import { Col, Collapse, Divider, Input, List, Modal, Row, Space, Tag, Typography } from 'antd';
const { Title, Text } = Typography;
const { TextArea } = Input;

function JobResultModal(props) {
    const is2xx = (status) => { return (status >= 200 && status <= 299) }

    const timeDifference = (start_time, end_time) => {
        const start = new Date(start_time)
        const end = new Date(end_time)
        const dt = end - start
        const ms = (dt % 1000).toString().padStart(3, '0')
        const ss = Math.floor(dt / 1000) % 60
        return `${ss}.${ms} s`
    }

    const formatDataTime = (start_time) => {
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        };
        let datetime = new Date(start_time)
        return datetime.toLocaleDateString("en-US", options)
    }

    let job_result_items = []
    const job_fail_sucess_counter = props?.data?.job_fail_success_counter

    if ('data' in props && props.data != null) {
        const data = props.data
        data.job_results.sort((a, b) => new Date(b.start_time) - new Date(a.start_time)); /* Descending order */
        const length = data.job_results.length < 10 ? data.job_results.length : 10
        for (let i = 0; i < length; i++) {
            const http_method = data.job_results[i].http_method
            const http_url = data.job_results[i].http_url
            const response_status = data.job_results[i].response_status
            const response_payload = data.job_results[i].response_payload
            const start_time = data.job_results[i].start_time
            const end_time = data.job_results[i].end_time
            const time_difference = timeDifference(start_time, end_time)
            job_result_items[i] = {
                key: i,
                label: <>
                    <Space size={'small'}>
                        <Tag color={is2xx(response_status) ? "success" : "error"} >
                            {response_status}
                        </Tag>
                        <Text>{formatDataTime(start_time)}</Text>
                        <Text>|</Text>
                        <Text>{time_difference}</Text>
                    </Space>
                </>,
                children:
                    <>
                        <List
                            itemLayout="horizontal"
                            dataSource={[
                                {
                                    title: 'Status, method, URL',
                                    content:
                                        <>
                                            <Tag color={is2xx(response_status) ? "success" : "error"} >
                                                {response_status}
                                            </Tag>
                                            <Tag>{http_method}</Tag>
                                            <Text> {http_url}</Text>
                                        </>
                                },
                                {
                                    title: 'Execution time',
                                    content:
                                        <>
                                            <Text> {time_difference}</Text>
                                        </>
                                },
                                {
                                    title: 'Response',
                                    content:
                                        <>
                                            <TextArea
                                                value={response_payload}
                                                autoSize={{ minRows: 6, maxRows: 6, }}
                                            />
                                        </>
                                }
                            ]}
                            renderItem={(item) =>
                                <List.Item>
                                    <List.Item.Meta
                                        title={item.title}
                                        description={item.content}
                                    />
                                </List.Item>
                            }
                        >
                        </List>
                    </>,
            }
        }
    }

    const onCancel = () => {
        props.setOpenClose(false)
    }

    const onSave = () => {
        props.setOpenClose(false)
    }

    return (
        <>
            <Modal
                title={<Title>{props.title}</Title>}
                width={650}
                open={props.open}
                setOpenClose={props.setOpenClose}
                onOk={onSave}
                onCancel={onCancel}
                loading={props.loading}
            >
                {/* <Title level={3}>{props?.data?.user_job_name}</Title> */}
                <Collapse
                    items={job_result_items}
                    defaultActiveKey={[]}
                    accordion
                />
                <Divider />
                <Row>
                    <Col span={8}>
                        <Text strong>Total successes: {job_fail_sucess_counter?.tot_success_nb} </Text>
                    </Col>
                    <Col span={8}>
                        <Text strong>Total fails: {job_fail_sucess_counter?.tot_fail_nb}</Text>
                    </Col>
                    <Col span={8}>
                        <Text strong>Consecutive fails: {job_fail_sucess_counter?.consecutive_fail_nb}</Text>
                    </Col>
                </Row>
            </Modal>
        </>
    )

}

export default JobResultModal;