import { Button, Layout, Result } from 'antd';
import { Link } from "react-router-dom";

const AccountSettingsPage = () => {
    return (
        <div>
            <Layout style={{ minHeight: '100vh' }}>
                <Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={<Link to='/'><Button type="primary">Back Home</Button></Link>}
                />
            </Layout>
        </div>
    );
}

export default AccountSettingsPage;