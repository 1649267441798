function generate_text(n) {
    var text = "text ";
    for (var i = 0; i < n; i++)
        text += text;
    return text;
}

function truncateString(str, n) {
    return (str?.length > n) ? str.slice(0, n - 1) : str;
};
function convertToLocalDateTime(isoDateString) {
    // Create a new Date object from the ISO string
    const date = new Date(isoDateString);

    // Get year, month, day, hours, minutes, and seconds
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Return formatted string
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function isPrivateIP(ip) {
    var parts = ip.split('.');
    return parts[0] === '10' || 
       (parts[0] === '172' && (parseInt(parts[1], 10) >= 16 && parseInt(parts[1], 10) <= 31)) || 
       (parts[0] === '192' && parts[1] === '168');
 }

 function isIPAddress(hostname) {
    // Regular expression to check if a string is a valid IPv4 address
    const ipv4Pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    
    // Regular expression to check if a string is a valid IPv6 address
    const ipv6Pattern = /^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}$/;
    
    // Test the hostname against the IPv4 and IPv6 patterns
    return ipv4Pattern.test(hostname) || ipv6Pattern.test(hostname);
  }

  function isIPV4Address(hostname) {
    // Regular expression to check if a string is a valid IPv4 address
    const ipv4Pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    
    // Test the hostname against the IPv4 and IPv6 patterns
    return ipv4Pattern.test(hostname);
  }


export { generate_text, truncateString, convertToLocalDateTime,isPrivateIP,isIPV4Address };