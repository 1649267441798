import React, { useEffect, useState } from 'react';
// ANT
import { CaretRightOutlined, CloseCircleFilled, PauseOutlined, SaveFilled } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Modal, Radio, Row, Select, Switch, Tabs, Typography } from 'antd';
import timezones from './timezones';
// REACT ROUTER DOM

import apiconfig from '../apiconfig';
import { isIPV4Address, isPrivateIP } from '../utils/utils';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { HTTP_201_CREATED, HTTP_202_ACCEPTED } from '../utils/http_codes';

const { Title, Text } = Typography;
const { Item } = Form;
const { TextArea } = Input;

const timezones_options = timezones.map(tz => ({ label: tz, value: tz }))

const minutes = []
const hours = []
const days = []
const months = []
const day_of_week = []
const months_names = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
const days_of_week_names = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']

/* MINUTES */
for (let i = 0; i < 60; i++) {
    minutes.push({
        value: i,
        label: i
    })
}
/* HOURS */
for (let i = 0; i < 24; i++) {
    hours.push({
        value: i,
        label: i
    })
}
/* DAYS */
for (let i = 1; i < 32; i++) {
    days.push({
        value: i,
        label: i
    })
}
/* MONTHS */
for (let i = 1; i < 13; i++) {
    months.push({
        value: i,
        label: months_names[i - 1]
    })
}
/* DAYS OF THE WEEK */
for (let i = 1; i < 8; i++) {
    day_of_week.push({
        value: i,
        label: days_of_week_names[i - 1]
    })
}

function JobModal(props) {
    const local_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const [form] = Form.useForm()
    const [schedule_type, setScheduleType] = useState(props.mode === 'create' ? 'interval' : props?.update_data?.schedule_type)//useState('interval')
    const [email_notify, setEmailNotify] = useState(true)
    const [minutes_select_disabled, setMinutesSelectDisabled] = useState(true)
    const [hours_select_disabled, setHoursSelectDisabled] = useState(true)
    const [days_select_disabled, setDaysSelectDisabled] = useState(true)
    const [months_select_disabled, setMonthsSelectDisabled] = useState(true)
    const [days_of_week_select_disabled, setDaysOfWeekSelectDisabled] = useState(true)
    // const [create_save_loading, setCreateSaveLoading] = useState(false)

    const { authTokens } = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const jobCreationSucceeded = () => {
        props?.messageApi.open({
            type: "success",
            content: "Job created.",
            duration: 3,
            maxCount: 10,
        });
    };

    const jobCreationFailed = () => {
        props?.messageApi.open({
            type: "error",
            content: "Error, the job has not been created.",
            duration: 3,
            maxCount: 10,
        });
    };

    const jobUpdatedSucceeded = () => {
        props?.messageApi.open({
            type: "success",
            content: "Job updated.",
            duration: 3,
            maxCount: 10,
        });
    };

    const jobUpdatedFailed = () => {
        props?.messageApi.open({
            type: "error",
            content: "Error, the job has not been updated.",
            duration: 3,
            maxCount: 10,
        });
    };

    const formError = (err) => {
        props?.messageApi.open({
            type: "error",
            content: err,
            duration: 3,
            maxCount: 10,
        });
    };

    useEffect(() => {
        if (props.mode === 'create') {
            /* SET INTERVAL AS DEFAULT */
            setScheduleType('interval')

            /* MAKE CRON SELECTIONS DISABLED */
            setMinutesSelectDisabled(true)
            setHoursSelectDisabled(true)
            setDaysSelectDisabled(true)
            setMonthsSelectDisabled(true)
            setEmailNotify(true)

            /* SET FORM DEFAULT VALUES */
            form.setFieldsValue({
                user_job_name: '',
                schedule_type: 'interval',
                every: 15,
                period: 'minutes',
                http_url: '',
                http_method: 'GET',
                http_headers: '',
                http_payload: '',
                http_auth_user: '',
                http_auth_passwd: '',
                enabled: true,
                email_notify: true,
                email_notify_fail_nb: 3,
                minutes_radio: 'every',
                hours_radio: 'every',
                days_radio: 'every',
                months_radio: 'every',
                days_of_week_radio: 'every',
                minutes_select: [],
                hours_select: [],
                days_select: [],
                months_select: [],
                days_of_week_select: [],
                timezone: local_timezone ? local_timezone : 'UTC',
            })
        }
        else if (props.mode === 'update') {
            setScheduleType(props.update_data.schedule_type)

            if (props.update_data.schedule_type === 'interval') {
                setMinutesSelectDisabled(true)
                setHoursSelectDisabled(true)
                setDaysSelectDisabled(true)
                setMonthsSelectDisabled(true)
                setEmailNotify(props.update_data.email_notify)

                form.setFieldsValue({
                    user_job_name: props.update_data.user_job_name,
                    schedule_type: props.update_data.schedule_type,
                    every: props.update_data.interval.every,
                    period: props.update_data.interval.period,
                    http_url: props.update_data.http_url,
                    http_method: props.update_data.http_method,
                    http_headers: props.update_data.http_headers,
                    http_payload: props.update_data.http_payload,
                    http_auth_user: props.update_data.http_auth_user,
                    http_auth_passwd: props.update_data.http_auth_passwd,
                    enabled: props.update_data.enabled,
                    email_notify: props.update_data.email_notify,
                    email_notify_fail_nb: props.update_data.email_notify_fail_nb,
                    /* SET CRON DEFAULT VALUES */
                    minutes_radio: 'every',
                    hours_radio: 'every',
                    days_radio: 'every',
                    months_radio: 'every',
                    days_of_week_radio: 'every',
                    minutes_select: [],
                    hours_select: [],
                    days_select: [],
                    months_select: [],
                    days_of_week_select: [],
                    /* ---------------------------------------- */
                    timezone: props.update_data.timezone,
                })
            }
            else if (props.update_data.schedule_type === 'cron') {
                let minutes = []
                let hours = []
                let days = []
                let months = []
                let days_of_week = []

                if (props.update_data?.crontab?.minute !== '*') {
                    minutes = (props.update_data?.crontab?.minute)?.split(',')
                }

                if (props.update_data?.crontab?.hour !== '*') {
                    hours = (props.update_data?.crontab?.hour)?.split(',')
                }

                if (props.update_data?.crontab?.day_of_month !== '*') {
                    days = (props.update_data?.crontab?.day_of_month)?.split(',')
                }
                if (props.update_data?.crontab?.month_of_year !== '*') {
                    months = (props.update_data?.crontab?.month_of_year)?.split(',')
                }

                if (props.update_data?.crontab?.day_of_week !== '*') {
                    days_of_week = (props.update_data?.crontab?.day_of_week)?.split(',')
                }

                /* ENABLE / DISABLE SELECTION */
                props.update_data?.crontab?.minute === '*' ? setMinutesSelectDisabled(true) : setMinutesSelectDisabled(false)
                props.update_data?.crontab?.hour === '*' ? setHoursSelectDisabled(true) : setHoursSelectDisabled(false)
                props.update_data?.crontab?.day_of_month === '*' ? setDaysSelectDisabled(true) : setDaysSelectDisabled(false)
                props.update_data?.crontab?.month_of_year === '*' ? setMonthsSelectDisabled(true) : setMonthsSelectDisabled(false)
                props.update_data?.crontab?.day_of_week === '*' ? setDaysOfWeekSelectDisabled(true) : setDaysOfWeekSelectDisabled(false)

                setEmailNotify(props.update_data.email_notify)

                form.setFieldsValue({
                    user_job_name: props.update_data.user_job_name,
                    schedule_type: props.update_data.schedule_type,
                    /* SET INTERVAL DEFAULT VALUES */
                    every: 15,
                    period: 'minutes',
                    /* ---------------------------------------- */
                    http_url: props.update_data.http_url,
                    http_method: props.update_data.http_method,
                    http_headers: props.update_data.http_headers,
                    http_payload: props.update_data.http_payload,
                    http_auth_user: props.update_data.http_auth_user,
                    http_auth_passwd: props.update_data.http_auth_passwd,
                    enabled: props.update_data.enabled,
                    email_notify: props.update_data.email_notify,
                    email_notify_fail_nb: props.update_data.email_notify_fail_nb,
                    minutes_radio: props.update_data.crontab.minute === '*' ? 'every' : 'select',
                    hours_radio: props.update_data.crontab.hour === '*' ? 'every' : 'select',
                    days_radio: props.update_data.crontab.day_of_month === '*' ? 'every' : 'select',
                    months_radio: props.update_data.crontab.month_of_year === '*' ? 'every' : 'select',
                    days_of_week_radio: props.update_data.crontab.day_of_week === '*' ? 'every' : 'select',
                    minutes_select: minutes,
                    hours_select: hours,
                    days_select: days,
                    months_select: months,
                    days_of_week_select: days_of_week,
                    timezone: props.update_data.timezone,
                })
            }
        }
    }, [props.update_data, props.mode])


    const prepareJSON = (input_obj) => {
        let interval = null
        let crontab = null

        if (input_obj.schedule_type === "interval") {
            interval = {
                "every": input_obj.every,
                "period": input_obj.period,
            }
        }
        else if (input_obj.schedule_type === "cron") {
            let minutes = ""
            let hours = ""
            let days = ""
            let months = ""
            let days_of_week = ""
            /* MINUTES RADIO */
            switch (input_obj.minutes_radio) {
                case "every":
                    minutes = "*"
                    break;
                case "select":
                    minutes = input_obj.minutes_select.join(',')
                    break;
                default:
                    throw new TypeError("'minutes_radio' value error!")
            }
            /* HOURS RADIO */
            switch (input_obj.hours_radio) {
                case "every":
                    hours = "*"
                    break;
                case "select":
                    hours = input_obj.hours_select.join(',')
                    break;
                default:
                    throw new TypeError("'hours_radio' value error!")
            }
            /* DAYS RADIO */
            switch (input_obj.days_radio) {
                case "every":
                    days = "*"
                    break;
                case "select":
                    days = input_obj.days_select.join(',')
                    break;
                default:
                    throw new TypeError("'days_radio' value error!")
            }
            /* MONTHS RADIO */
            switch (input_obj.months_radio) {
                case "every":
                    months = "*"
                    break;
                case "select":
                    months = input_obj.months_select.join(',')
                    break;
                default:
                    throw new TypeError("'months_radio' value error!")
            }
            /* DAYS OF WEEK RADIO */
            switch (input_obj.days_of_week_radio) {
                case "every":
                    days_of_week = "*"
                    break;
                case "select":
                    days_of_week = input_obj.days_of_week_select.join(',')
                    break;
                default:
                    throw TypeError("'days_of_week_radio' value error!")
            }

            crontab = {
                "minute": minutes,
                "hour": hours,
                "day_of_month": days,
                "day_of_week": days_of_week,
                "month_of_year": months,
            }
        }
        else {
            throw TypeError("'schedule_type' value error!")
        }

        const output_object = {
            "user_job_name": input_obj.user_job_name,
            "schedule_type": input_obj.schedule_type,
            "http_url": input_obj.http_url,
            "interval": interval,
            "crontab": crontab,
            "http_method": input_obj.http_method,
            "http_auth_user": input_obj.http_auth_user,
            "http_auth_passwd": input_obj.http_auth_passwd,
            "http_headers": input_obj.http_headers,
            "http_payload": input_obj.http_payload,
            "enabled": input_obj.enabled,
            "email_notify": input_obj.email_notify,
            "email_notify_fail_nb": input_obj.email_notify_fail_nb,
            "timezone": input_obj.timezone,
        }
        return output_object
    }

    const createPOST = async (input_obj) => {
        const output_obj = prepareJSON(input_obj)
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            }
        };
        try {
            const response = await axiosPrivate.post(apiconfig.API_JOBS, JSON.stringify(output_obj), config);
            if (response.status === HTTP_201_CREATED) {
                props.tableReaload()
                jobCreationSucceeded()
            }
        } catch (err) {
            console.error('Can\'t create the job!')
            jobCreationFailed()
        }
    }

    const updatePATCH = async (input_obj) => {
        const output_obj = prepareJSON(input_obj)
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            }
        };

        try {
            const response = await axiosPrivate.patch(apiconfig.API_JOBS + (props.update_data.id).toString(), JSON.stringify(output_obj), config);
            if (response.status === HTTP_202_ACCEPTED) {
                props.tableReaload()
                jobUpdatedSucceeded()
            }
        } catch (err) {
            console.error('Can\'t update the job!')
            jobUpdatedFailed()
        }
    }

    const scheduleTypeChanged = (e) => {
        setScheduleType(e.target.value)
    }

    const onSave = async (e) => {
        if (props.mode === 'create') {
            try {
                const values = await form.validateFields();
                createPOST(values);
                props.setOpenClose(false);
            } catch (errorInfo) {
                errorInfo.errorFields.forEach(field => {
                    formError(`${field.errors.join(', ')}`);
                });
            }
        }
        else if (props.mode === 'update') {
            try {
                const values = await form.validateFields();
                updatePATCH(values);
                props.setOpenClose(false);
            } catch (errorInfo) {
                errorInfo.errorFields.forEach(field => {
                    formError(`${field.errors.join(', ')}`);
                });
            }
        }
    }

    const onCancel = () => {
        props.setOpenClose(false)
    }

    const validateTargetURL = (rule, value) => {
        if (!value)
            return Promise.reject(new Error('Please type URL of the target!'));
        try {
            const url = new URL(value);
            if (url.hostname === 'localhost' || url.hostname === '127.0.0.1')
                return Promise.reject(new Error('URL cannot be localhost or 127.0.0.1'));

            if (url.hostname === 'cronbe.com')
                return Promise.reject(new Error('URL cannot be cronbe.com'));

            if (isIPV4Address(url.hostname)) {
                if (isPrivateIP(url.hostname)) {
                    return Promise.reject(new Error('IP address cannot be private!'));
                }
            }
            return Promise.resolve();
        }
        catch (e) {
            return Promise.reject(new Error('This field must be a valid URL.'));
        }
    };

    /* TABS */
    const items = [
        {
            key: '1',
            forceRender: true,
            label: <Text strong>GENERAL</Text>,
            children:
                <div>
                    <Item label="Job name" name="user_job_name" rules={[{ required: true, message: 'Please type a job name!' }]}>
                        <Input style={{ width: '100%', minWidth: '100px' }} placeholder="Job name" />
                    </Item>
                    <Item
                        label="Target URL"
                        name="http_url"
                        rules={[{ required: true, validator: validateTargetURL }]}
                    >
                        <Input addonBefore={'URL'} placeholder="https://example.com/" />
                    </Item>
                    <Row justify="space-around">
                        <Col span={11}>
                            <Item required label="Schedule type" name="schedule_type">
                                <Radio.Group onChange={scheduleTypeChanged} value={schedule_type}>
                                    <Radio.Button value="interval"><Text strong>Interval</Text></Radio.Button>
                                    <Radio.Button value="cron"><Text strong>Cron</Text></Radio.Button>
                                </Radio.Group>
                            </Item>
                        </Col >
                        <Col span={2} />
                        <Col span={11}>
                            <Item valuePropName="checked" label="Enabled" name="enabled" required>
                                <Switch
                                    checkedChildren={<CaretRightOutlined />}
                                    unCheckedChildren={<PauseOutlined />}
                                />
                            </Item>
                        </Col>
                    </Row>

                    {schedule_type === "interval" && (
                        <>
                            <Row justify="space-around">
                                <Col span={11} >
                                    <Item label="Run every" name="every" rules={[{ required: true, message: 'Please chose time interval!' }]} >
                                        <InputNumber
                                            defaultValue='1'
                                            style={{ width: '100%', minWidth: '100px' }}
                                            min={1}
                                            max={null}
                                            placeholder="Time interval"
                                        />
                                    </Item>
                                </Col>
                                <Col span={2}></Col>
                                <Col span={11}>
                                    <Item label="Unit" name="period" rules={[{ required: true, message: 'Please chose time unit!' }]}>
                                        <Select
                                            placeholder="Interval time unit"
                                            options={[
                                                { value: 'minutes', label: 'Minutes' },
                                                { value: 'hours', label: 'Hours' },
                                                { value: 'days', label: 'Days' },
                                            ]}
                                        />
                                    </Item>
                                </Col>
                            </Row>
                        </>
                    )}

                    {schedule_type === "cron" && (
                        <>
                            <Row justify="space-around">
                                <Col span={4}>
                                    <Item label="Minutes" name="minutes_radio" required>
                                        <Radio.Group
                                            defaultValue={'every'}
                                            options={
                                                [
                                                    {
                                                        label: 'Every',
                                                        value: 'every',
                                                    },
                                                    {
                                                        label: 'Select',
                                                        value: 'select',
                                                    },
                                                ]
                                            }
                                            onChange={(e) => {
                                                e.target.value === 'select' ? setMinutesSelectDisabled(false) : setMinutesSelectDisabled(true)
                                                form.setFieldsValue({ minutes_select_disable: undefined })
                                            }}
                                        />
                                    </Item>
                                    <Item
                                        name="minutes_select"
                                        rules={[
                                            {
                                                required: !minutes_select_disabled,
                                                message: 'Please select minutes!',
                                                type: 'array',
                                            },
                                        ]}
                                    >

                                        <Select
                                            disabled={minutes_select_disabled}
                                            style={{ width: '80px' }}
                                            mode="tags"
                                            options={minutes}
                                        />
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item label="Hours" name="hours_radio" required>
                                        <Radio.Group
                                            defaultValue={'every'}
                                            options={
                                                [
                                                    {
                                                        label: 'Every',
                                                        value: 'every',
                                                    },
                                                    {
                                                        label: 'Select',
                                                        value: 'select',
                                                    },
                                                ]
                                            }
                                            onChange={(e) => {
                                                e.target.value === 'select' ? setHoursSelectDisabled(false) : setHoursSelectDisabled(true)
                                                form.setFieldsValue({ hours_select: undefined })
                                            }}
                                        />
                                    </Item>
                                    <Item
                                        name="hours_select"
                                        rules={[
                                            {
                                                required: !hours_select_disabled,
                                                message: 'Please select hours!',
                                                type: 'array',
                                            },
                                        ]}>
                                        <Select
                                            disabled={hours_select_disabled}
                                            style={{ width: '80px' }}
                                            mode="tags"
                                            options={hours}
                                        />
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item label="Days" name="days_radio" required>
                                        <Radio.Group
                                            defaultValue={'every'}
                                            options={
                                                [
                                                    {
                                                        label: 'Every',
                                                        value: 'every',
                                                    },
                                                    {
                                                        label: 'Select',
                                                        value: 'select',
                                                    },
                                                ]
                                            }
                                            onChange={(e) => {
                                                e.target.value === 'select' ? setDaysSelectDisabled(false) : setDaysSelectDisabled(true)
                                                form.setFieldsValue({ days_select: undefined })
                                            }}
                                        />
                                    </Item>
                                    <Item name="days_select"
                                        rules={[
                                            {
                                                required: !days_select_disabled,
                                                message: 'Please select days!',
                                                type: 'array',
                                            },
                                        ]}
                                    >
                                        <Select
                                            disabled={days_select_disabled}
                                            style={{ width: '80px' }}
                                            mode="tags"
                                            options={days}
                                        />
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item label="Months" name="months_radio" required>
                                        <Radio.Group
                                            defaultValue={'every'}
                                            options={
                                                [
                                                    {
                                                        label: 'Every',
                                                        value: 'every',
                                                    },
                                                    {
                                                        label: 'Select',
                                                        value: 'select',
                                                    },
                                                ]
                                            }
                                            onChange={(e) => {
                                                e.target.value === 'select' ? setMonthsSelectDisabled(false) : setMonthsSelectDisabled(true)
                                                form.setFieldsValue({ months_select: undefined })
                                            }}
                                        />
                                    </Item>
                                    <Item name="months_select"
                                        rules={[
                                            {
                                                required: !months_select_disabled,
                                                message: 'Please select months!',
                                                type: 'array',
                                            },
                                        ]}
                                    >
                                        <Select
                                            required
                                            disabled={months_select_disabled}
                                            style={{ width: '90px' }}
                                            mode="tags"
                                            options={months}
                                        />
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item label="Days of week" name="days_of_week_radio" required>
                                        <Radio.Group
                                            defaultValue={'every'}
                                            options={
                                                [
                                                    {
                                                        label: 'Every',
                                                        value: 'every',
                                                    },
                                                    {
                                                        label: 'Select',
                                                        value: 'select',
                                                    },
                                                ]
                                            }
                                            onChange={(e) => {
                                                e.target.value === 'select' ? setDaysOfWeekSelectDisabled(false) : setDaysOfWeekSelectDisabled(true)
                                                form.setFieldsValue({ days_of_week_select: undefined })
                                            }}
                                        />
                                    </Item>
                                    <Item name="days_of_week_select"
                                        rules={[
                                            {
                                                required: !days_of_week_select_disabled,
                                                message: 'Please select days of week!',
                                                type: 'array',
                                            },
                                        ]}
                                    >
                                        <Select
                                            disabled={days_of_week_select_disabled}
                                            style={{ width: '90px' }}
                                            mode="multiple"
                                            options={day_of_week}
                                        />
                                    </Item>
                                </Col>
                            </Row>
                        </>
                    )
                    }
                    <Item label="Timezone" name='timezone' required>
                        <Select
                            options={timezones_options} />
                    </Item>
                </div >,
        },
        {
            key: '2',
            forceRender: true,
            label: <Text strong>HTTP REQUEST</Text>,
            children:
                <div>
                    <Item required label="HTTP method" name="http_method">
                        <Select
                            defaultValue='GET'
                            options={[
                                { value: 'GET', label: 'GET' },
                                { value: 'POST', label: 'POST' },
                                { value: 'PUT', label: 'PUT' },
                                { value: 'PATCH', label: 'PATCH' },
                                { value: 'DELETE', label: 'DELETE' },
                                { value: 'HEAD', label: 'HEAD' },
                                { value: 'OPTIONS', label: 'OPTIONS' },
                            ]}
                        />
                    </Item>
                    <Row justify="space-around">
                        <Col span={11}>
                            <Item label="Username" name="http_auth_user" tooltip="Basic authentication username">
                                <Input placeholder="Username" />
                            </Item>
                        </Col>
                        <Col span={1} />
                        <Col span={11}>
                            <Item label="Password" name="http_auth_passwd" tooltip="Basic authentication password">
                                <Input placeholder="Password" />
                            </Item>
                        </Col>
                    </Row>
                    <Item label="Headers" name="http_headers" tooltip="HTTP headers">
                        <TextArea
                            placeholder="HTTP headers"
                            autoSize={{
                                minRows: 4,
                                maxRows: 6,
                            }}
                        />
                    </Item>
                    <Item label="Payload" name="http_payload" tooltip="HTTP payload">
                        <TextArea
                            placeholder="HTTP payload"
                            autoSize={{
                                minRows: 4,
                                maxRows: 6,
                            }}
                        />
                    </Item>
                </div >,
        },
        {
            key: '3',
            forceRender: true,
            label: <Text strong>NOTIFICATIONS</Text>,
            children:
                <div>
                    <Item label="Email notification" name="email_notify" required>
                        <Switch
                            checked={email_notify}
                            onChange={(e) => {
                                setEmailNotify(e);
                            }}
                            checkedChildren="On"
                            unCheckedChildren="Off"
                        />
                    </Item>
                    <Item label="Notify me after:" name="email_notify_fail_nb" required>
                        <Select
                            disabled={!email_notify}
                            options={[
                                { value: 1, label: '1 failure' },
                                { value: 2, label: '2 failures' },
                                { value: 3, label: '3 failures' },
                                { value: 4, label: '4 failures' },
                                { value: 5, label: '5 failures' },
                                { value: 6, label: '6 failures' },
                                { value: 7, label: '7 failures' },
                                { value: 8, label: '8 failures' },
                                { value: 9, label: '9 failures' },
                                { value: 10, label: '10 failures' },
                            ]}
                        />
                    </Item>
                </div >
        },
    ];

    return <div>
        <Modal
            title={<Title>{props.title}</Title>}
            width={650}
            open={props.open}
            onOk={onSave}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel} icon={<CloseCircleFilled />} >Cancel</Button>,
                <Button key="submit" type="primary" onClick={onSave} icon={<SaveFilled />}>
                    {props.mode === 'create' ? 'Save' : 'Update'}
                </Button>,
            ]}
        >
            <Form
                form={form}
                requiredMark={'optional'}
                layout='vertical'
            >
                <Tabs defaultActiveKey='1' items={items} />
            </Form>
        </Modal>
    </div>
}
export default JobModal;