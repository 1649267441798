import { DownOutlined, LogoutOutlined, MenuOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { Drawer, Dropdown, Layout, Space, Typography, theme, Avatar } from "antd";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import "./headerbar.css";

const { Header } = Layout;
const { Title, Text } = Typography;


const HeaderBar = ({ menu, toggleSiderCollapsed, drawerOpen, setDrawerOpen }) => {
    const { useToken } = theme;
    const { token } = useToken();
    const { logout, userProfile } = useAuth();

    const items = [
        {
            key: 'settings',
            label: <>
                <Link to='/settings'>Settings</Link>
            </>,
            icon: <SettingOutlined />,
            onClick: null,
        },
        {
            key: 'logout',
            label:
                <>
                    <Text>Logout</Text>
                </>,
            icon: <LogoutOutlined />,
            onClick: logout,
        }
    ];

    return (
        <Header
            style={{
                height: '80px',
                display: 'flex',
                padding: 0,
                alignItems: 'center',
                backgroundColor: token.colorBgBlur,
                borderBottomWidth: 1,
                borderBottomColor: token.colorBorder,
                borderBottomStyle: 'solid',
            }}
        >
            <div
                className="hamburgerMenu"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '80px'
                }}
                onClick={() => { toggleSiderCollapsed() }}>
                <MenuOutlined className="hamburgerMenu" style={{ fontSize: '25px', color: token.colorPrimary }} />
            </div>
            <div
                className="hamburgerDrawer"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '80px'
                }}
                onClick={() => setDrawerOpen(true)}>
                <MenuOutlined className="hamburgerDrawer" style={{ fontSize: '25px', color: token.colorPrimary }} />
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Title style={{ padding: '10px', margin: 0 }}>CRONBE</Title>
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'right', textAlign: 'right', paddingRight: '28px' }}>
                <Dropdown width='200px' menu={{ items, }} trigger={['click']} placement="bottomRight">
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <Avatar shape="square" icon={<UserOutlined />} />
                            <Text strong>{userProfile?.first_name}</Text>
                            <Text strong><DownOutlined /></Text>
                        </Space>
                    </a>
                </Dropdown>
            </div>
            <Drawer
                placement="left"
                closable={false}
                onClose={() => setDrawerOpen(false)}
                open={drawerOpen}
                style={{
                    background: token.colorBgContainer,
                    width: '250px'
                }}
            >
                {menu}
            </Drawer>
        </Header >
    );
};
export default HeaderBar;